<template>
  <div class="content-login">
    <div class="content-login-itens container">
      <div class="content-login-itens-header">
        <h1>{{ $t('register.login_an_account') }}</h1>
      </div>
      <!--- FORM LOGIN -->
      <div class="col-sm-8 col-lg-5 mx-auto">
        <form-login />
      </div>
      <!----------------->
    </div>
  </div>
</template>

<script>
import FormLogin from "./FormLogin.vue";
export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  components: {
    FormLogin,
  },
};
</script>

<style lang="scss">
.content-login {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      padding-top: 45px;
      .content-elements {
        &-header {
          text-align: center;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
            }
          }
        }
      }
      .btn-create {
        height: 55px;
        width: 100%;
        background: var(--main-color5);
        border: 1px solid var(--main-color5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color);
        text-transform: uppercase;
        margin-bottom: 30px;
        transition: 500ms;
        &:hover,
        &:focus {
          background: var(--main-color);
          color: var(--main-color5);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          height: 50px;
        }
        @media (max-width: 359px) {
          font-size: 16px;
          height: 45px;
        }
      }
      .btns-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>